import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _times from 'lodash/times';
import flexbox from 'styles/utils/flexbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const propTypes = {
  stars: PropTypes.number.isRequired,
};

function Stars({ stars, ...props }) {
  const halfStar = stars % 1 !== 0;

  return (
    <Root {...props}>
      {_times(stars, (i) => {
        return <FontAwesomeIcon key={i} icon='star' />;
      })}
      {halfStar && (
        <HalfStar>
          <Icon icon='star' />
        </HalfStar>
      )}
    </Root>
  );
}

Stars.propTypes = propTypes;

export default Stars;

const Root = styled.div`
  ${flexbox({
    flexDirection: 'row',
  })}
  height: 100%;
  font-size: ${({ isSmallCard }) => (isSmallCard ? '12px' : '14px ')};
  color: ${({ theme }) => theme.colors.stars.primary};
  gap: 2px;
`;

const HalfStar = styled.div`
  display: inline-flex;
  position: relative;
  height: auto;
`;

const Icon = styled(FontAwesomeIcon)`
  clip-path: polygon(0% 0%, 65% 0%, 65% 100%, 0% 100%);
`;
