import styled from 'styled-components';

const CornerRibbonComponent = styled.div`
  position: absolute;
  right: -5px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 120px;
  height: 120px;
  text-align: right;

  span {
    position: absolute;
    top: 30px;
    right: -20px;
    display: block;
    width: 130px;
    background: ${({ theme }) => theme.colors.adjacent.default};
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    transform: rotate(45deg);

    font-size: 13px;
    color: #fff;
    text-align: center;
    line-height: 20px;

    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
    }
  }
`;

export default CornerRibbonComponent;
