import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Measure from 'react-measure';
import htmlParser from 'utils/html/htmlParser';
import Tooltip from 'rc-tooltip';
import { FormattedMessage } from 'i18n';
import Heart from 'components/Heart';
import Stars from 'components/ratings/Stars';
import Score from 'components/ratings/Score';
import CarouselHotelImages from 'components/carousel/CarouselHotelImages';
import ReviewsCount from 'components/ratings/ReviewsCount';
import ReviewTooltip from './components/ReviewTooltipComponent';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';
import BaseShareHotels from 'components/shareHotels';

const propTypes = {
  cardId: PropTypes.string,
  carouselImages: PropTypes.array.isRequired,
  hotelName: PropTypes.string.isRequired,
  hotelCity: PropTypes.string.isRequired,
  hotelStars: PropTypes.number.isRequired,
  hotelDescription: PropTypes.string,
  hotelScore: PropTypes.number.isRequired,
  subheader: PropTypes.bool,
  saved: PropTypes.bool,
  maxWidth: PropTypes.string,
  marginBottom: PropTypes.string,
  small: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClickRemoveHearted: PropTypes.func,
  onClickAddHearted: PropTypes.func,
};

const HotelCardComponent = ({
  cardId,
  carouselImages,
  hotelName,
  hotelCity,
  hotelStars,
  hotelDescription,
  hotelScore,
  reviewsCount,
  subheader,
  saved,
  maxWidth,
  marginBottom,
  small,
  onMouseEnter,
  onMouseLeave,
  onClickRemoveHearted,
  onClickAddHearted,
  airlineWhitelabel,
  showShareIcon,
  packageLink,
  displayStars,
}) => (
  <Measure client>
    {({ measureRef, contentRect }) => {
      const widthAbove900 = contentRect.client && contentRect.client.width && contentRect.client.width > 900;

      return (
        <PackageCardStyled
          id={cardId}
          maxWidth={maxWidth}
          marginBottom={marginBottom}
          small={small}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <SliderWrap small={small}>
            <HeartWrap>
              <Heart
                onClickRemoveHearted={onClickRemoveHearted}
                onClickAddHearted={onClickAddHearted}
                hearted={saved}
              />
            </HeartWrap>
            {carouselImages ? <CarouselHotelImages items={carouselImages} /> : null}
          </SliderWrap>
          <ContentWrap small={small}>
            <div>
              <Headline small={small} airlineWhitelabel={airlineWhitelabel}>
                {hotelName}
              </Headline>
              {subheader && (
                <Subheader small={small}>
                  {hotelCity && <HotelCity small={small}>{hotelCity}</HotelCity>}
                  {displayStars && hotelStars && (
                    <Tooltip
                      placement='topLeft'
                      trigger={['hover']}
                      overlay={
                        <FormattedMessage
                          id={'message.onhover.stars'}
                          description={'Official rating of hotel'}
                          defaultMessage={'Official rating of hotel'}
                        />
                      }
                      destroyTooltipOnHide
                    >
                      <HotelStars small={small}>
                        <Stars stars={hotelStars} />
                      </HotelStars>
                    </Tooltip>
                  )}
                </Subheader>
              )}
            </div>
            {hotelDescription ? <Description small={small}>{htmlParser(hotelDescription)}</Description> : null}
            {hotelScore ? (
              <Tooltip
                placement='top'
                trigger={['hover']}
                overlay={<ReviewTooltip reviewsCount={reviewsCount} widthAbove900={widthAbove900} />}
                destroyTooltipOnHide
              >
                <HotelScore small={small}>
                  {widthAbove900 && reviewsCount && <ReviewsCount reviewsCount={reviewsCount} hide={small} />}
                  <Score score={hotelScore} airlineWhitelabel={airlineWhitelabel} />
                </HotelScore>
              </Tooltip>
            ) : null}
            {showShareIcon ? (
              <ShareHotels packageLink={packageLink}>
                {(handleOnClickShare) => <ShareSingleHotel handleOnClickShare={handleOnClickShare} />}
              </ShareHotels>
            ) : null}
          </ContentWrap>
        </PackageCardStyled>
      );
    }}
  </Measure>
);

HotelCardComponent.propTypes = propTypes;

export default HotelCardComponent;
const ShareHotels = styled(BaseShareHotels)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const ShareSingleHotel = styled(ShareHotels.ShareIcon)({
  padding: '10px 0px',
  borderRadius: '4px',
});

// Styled Components
const PackageCardStyled = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 212px;
  width: auto;
  min-width: 700px;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'initial')};
  background-color: ${(props) => props.theme.colors.background.light};
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.1);
  transform: translate3d(0, 0, 0);
  transition: all 200ms ease-out;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin: 0 auto ${marginBottom};
  `}

  &:hover {
    transform: translate3d(0, -1px, 0);
    box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.2);
  }

  ${({ small }) =>
    small &&
    `
    flex-direction: column;
    width: 304px;
    min-width: 304px;
    height: auto;
  `}

  ${mediaQueries.hotelCard`
    flex-direction: column;
    width: 304px;
    min-width: 304px;
    height: auto;
  `}
`;

const HeartWrap = styled.div`
  position: absolute;
  top: 1rem;
  right: 0.875rem;
  width: 18px;
  z-index: 1;
`;

const SliderWrap = styled.div`
  width: 245px;
  height: 100%;
  position: relative;
  ${({ small }) =>
    small &&
    `
    width: 100%;
    height: 208px;
  `}

  ${mediaQueries.hotelCard`
    width: 100%;
    height: 208px;
  `}
`;

const ContentWrap = styled.div`
  ${flexbox({
    flexDirection: 'column',
    justifyContent: 'space-between',
  })}
  position: relative;
  padding: 0.75rem 1rem;
  width: 75%;
  min-width: 280px;

  border: 1px solid ${(props) => props.theme.colors.primary.default};
  border-width: 1px 0 1px 0;

  ${({ small }) =>
    small &&
    `
    width: 100%;
    min-height: 90px;
    border-width: 0 1px 0px 1px;
  `}

  ${mediaQueries.hotelCard`
    width: 100%;
    min-height: 90px;
    border-width: 0 1px 0px 1px;
  `}
`;

const Headline = styled.h2`
  margin-right: 38px;
  min-height: 23px;
  max-height: 40px;
  color: ${(props) => props.theme.colors.secondary.default};
  font-size: 20px;
  font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 600)};
  overflow: hidden;

  ${({ small }) =>
    small &&
    `
    font-size: 18px;
  `}

  ${mediaQueries.hotelCard`
    font-size: 18px;
  `}
`;

const Subheader = styled.div`
  ${flexbox({
    alignItems: 'center',
  })}
  margin-top: 4px;

  ${({ small }) =>
    small &&
    `
    margin-top: 0;
    flex-direction: column;
    align-items: initial;
  `}

  ${mediaQueries.hotelCard`
    margin-top: 0;
    flex-direction: column;
    align-items: initial;
  `}
`;

const HotelCity = styled.p`
  margin-top: 0 !important;
  margin-right: 0.5rem;
  font-size: 16px;
  font-weight: 500;

  ${({ small }) =>
    small &&
    `
    width: 100%;
    font-size: 14px;
  `}

  ${mediaQueries.hotelCard`
    width: 100%;
    font-size: 14px;
  `}
`;

const HotelStars = styled.div`
  height: 14px;

  img {
    display: initial !important;
  }

  ${({ small }) =>
    small &&
    `
    width: 100%;
    height: 12px;
    margin-top: 5px;
  `}

  ${mediaQueries.hotelCard`
    width: 100%;
    height: 12px;
    margin-top: 5px;
  `}
`;

const Description = styled.p`
  margin-top: 10px;
  margin-bottom: auto;
  display: block;
  width: 100%;
  min-width: 200px;
  max-width: 500px;
  max-height: 51px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.6px;

  ${({ small }) =>
    small &&
    `
    display: none;
  `}

  ${mediaQueries.hotelCard`
    display: none;
  `}
`;

const HotelScore = styled.div`
  position: absolute;
  right: 15px;
  bottom: ${({ small }) => small && '14px'};
`;
